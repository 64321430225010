


import { Component, Vue, Watch } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import vueCustomScrollbar from "vue-custom-scrollbar";
import stores from "@/store/store";
import { mixins } from "vue-class-component";
import QuestionnaireMixin from "../../../mixins/questionnaire.mixin";
const store: any = stores;
const PersonalInformation = () =>
  import(
    "../questionnaire-sections/personal-information/PersonalInformation.vue"
  );
const DemographicInformation = () =>
  import(
    "../questionnaire-sections/demographic-information/DemographicInformation.vue"
  );
const Declaration = () =>
  import("../questionnaire-sections/declaration/Declaration.vue");
const LoanAndProperty = () =>
  import("../questionnaire-sections/loan-and-property/LoanAndProperty.vue");
const IncomeInformation = () =>
  import("../questionnaire-sections/income-information/IncomeInformation.vue");
const AdditionalFinancial = () =>
  import(
    "../questionnaire-sections/additionalQuestion/AdditionalFinancial.vue"
  );
const LoanPurpose = () =>
  import("../questionnaire-sections/loan-purpose/LoanPurpose.vue");

@Component({
  components: {
    PersonalInformation,
    DemographicInformation,
    Declaration,
    LoanAndProperty,
    IncomeInformation,
    AdditionalFinancial,
    LoanPurpose,
    vueCustomScrollbar
  }
})
export default class QuestionnaireComponent extends mixins(QuestionnaireMixin) {
  /**
   * Call getter method to retrieve all the pos data from Vuex Store.
   */
  public dotLoader = false;
  public borrowerDetails: any = null;
  public logInBorrower: any = null;
  public questionHistory: any = null;
  public propData: any = null;
  public borrowers:any = null;
  public selectedBorrowerId = null;

  /********************************************************************************************************************************************************
   *                            This function return logInBorrowerName                                                                                    *
   ********************************************************************************************************************************************************/
  getLogInBorrower() {
    if (
      this.borrowerDetails &&
      this.borrowerDetails.borrower.userId == this.$route.query.borrowerId
    )
      this.logInBorrower = this.borrowerDetails.borrower;
    else
      this.logInBorrower = this.borrowerDetails.coBorrower
        ? this.borrowerDetails.coBorrower
        : this.borrowerDetails.borrower;
  }

  /********************************************************************************************************************************************************
   *                            GET QUESTONNAIRE HISTORY ON FIRST LOAD OF POS                                                                                 *
   ********************************************************************************************************************************************************/
  public get questionnaireTimeline() {
    let initailArr = [];
    if (this.questionHistory.currentSection == "AF") {
      initailArr = [
        {
          id: 1,
          name: "Gross Monthly Income",
          class: "not-current"
        },
        {
          id: 2,
          name: "Combined Monthly Housing Expense",
          class: "not-current"
        },
        { id: 3, name: "Asset", class: "not-current" },
        { id: 5, name: "Liabilities", class: "not-current" },
        { id: 6, name: "Real Estate Owned", class: "not-current" }
      ];
      if(this.$userType=='Borrower'){
        initailArr = [...initailArr, ...[{
          id: 4,
          name: "Credit Authorization",
          class: "not-current"
        }]];
      }
      initailArr.forEach(section => {
        switch (section.id) {
          case 1:
            if (this.questionHistory.currentQuestion >= 2)
              section.class = "current";
            break;
          case 2:
            if (this.questionHistory.currentQuestion >= 3)
              section.class = "current";
            break;
          case 3:
            if (this.questionHistory.currentQuestion >= 4)
              section.class = "current";
            break;
          case 4:
            if (this.questionHistory.currentQuestion >= 5)
              section.class = "current";
            break;
          case 5:
            if (this.questionHistory.currentQuestion >= 6)
              section.class = "current";
            break;
          case 6:
            if (this.questionHistory.currentQuestion == 7)
              section.class = "current";
            break;
        }
      });
    }
     else {
      initailArr = [
        {
          id: 2,
          name: "Personal Information",
          class: "not-current"
        },
        { id: 3, name: "Income Information", class: "not-current" },
        { id: 5, name: "Declaration", class: "not-current" },
        {
          id: 6,
          name: "Demographic Information",
          class: "not-current"
        }
      ];
      // if (this.questionHistory.userType == "borrower") {
      //   initailArr = [
      //     ...initailArr,
      //     ...[
      //       {
      //         id: 1,
      //         name: "Loan Purpose",
      //         class: "not-current"
      //       }
      //     ]
      //   ];
      // }
      if (this.questionHistory.userType == "coBorrower") {
        initailArr = [...initailArr];
      }
      if (this.questionHistory.userType == "borrower") {
        initailArr = [
          ...initailArr,
          ...[
            {
              id: 1,
              name: "Loan Purpose",
              class: "not-current"
            },
            {
              id: 4,
              name: "Loan and Property Information",
              class: "not-current"
            }
          ]
        ];
      }
      initailArr.forEach(section => {
        if (this.questionHistory.currentSection)
          switch (section.id) {
            case 1:
              if (this.questionHistory.currentSection)
                section.class = "current";
              break;
            case 2:
              if (this.questionHistory.currentSection != "LP")
                section.class = "current";
              break;
            case 3:
              if (
                this.questionHistory.currentSection != "PI" &&
                this.questionHistory.currentSection != "LP"
              )
                section.class = "current";
              break;
            case 4:
              if (
                this.questionHistory.currentSection != "PI" &&
                this.questionHistory.currentSection != "II" &&
                this.questionHistory.currentSection != "LP"
              )
                section.class = "current";
              break;
            case 5:
              if (
                this.questionHistory.currentSection != "PI" &&
                this.questionHistory.currentSection != "II" &&
                this.questionHistory.currentSection != "LAP" &&
                this.questionHistory.currentSection != "LP"
              )
                section.class = "current";
              break;
            case 6:
              if (this.questionHistory.currentSection == "DI")
                section.class = "current";
              break;
          }
      });
    }
    initailArr = initailArr.sort(function(a, b) {
      return a.id - b.id;
    });
    return initailArr;
  }

  /********************************************************************************************************************************************************
   *                            SHOW TIMELINE OF POS QUESTIONNAIRE                                                                               *
   ********************************************************************************************************************************************************/

  public showStepper() {
    if (this.questionHistory && this.questionHistory.currentSection == "AF") {
      return this.questionHistory.currentQuestion == 1 ? false : true;
    } else return true;
  }

  /*********************************************************************************************************************************
   *              This function return logInBorrower Name And Related (borrower/coBorrower Name)
   *********************************************************************************************************************************/
  public async getLogInBorrowerDetails() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "pos/getLogInBorrowerDetails",
        {
          loanTxnId: this.$route.query.loanTxnId,
          borrowerId: this.$route.query.borrowerId
        });
      this.borrowerDetails = response.data;
      this.getLogInBorrower();
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  /***************************************************************************************************************************************************************************
   *                                      This Function return Borrower question history
   ****************************************************************************************************************************************************************************/
  public async getQuestionHistory() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.get(
        BASE_API_URL + "pos/getQuestionnaireHistory",
        {
          params: {
            loanTxnId: this.$route.query.loanTxnId,
            borrowerId: this.$route.query.borrowerId
          }
        }
      );
      if (response.status == 200) {
        this.questionHistory = response.data.history;
        if (this.$userType == "Borrower" && this.questionHistory.answeredAll)
          this.$router.push({
            path: this.$getCurrentUserTypePath("/borrower/dashboard")
          });
      } else {
        this.$snotify.error("Some error occurred, please try after some time.");
        this.$router.go(-1);
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  /**************************************************************************************************************************************************************
   *                    This function update POS Question history                                                                                               *
   **************************************************************************************************************************************************************/
  public async updateQuestionHistory(data) {
    this.$store.state.wemloLoader = true;
    try {
      if (data) {
        let history = data.history;
        Object.keys(data.updatedHistory).forEach(key => {
          history[key] = data.updatedHistory[key];
        });
        history = { ...history, loanTxnId: this.$route.query.loanTxnId, activeUser: this.$userType };
        let response = await Axios.post(
          BASE_API_URL + "pos/saveQuestionnaireHistory",
          history);
        if (response.status == 200 && response.data.code == 200) {
          if(this.$userType != 'Borrower' && this.borrowers && this.borrowers.relationshipMapping.length > 1 && data.posCompleted)
            this.$modal.show('continuePosForBorrowers');
          else if(this.borrowers && this.borrowers.relationshipMapping.length == 1 && data.posCompleted)
            this.getRedirectRouteAfterCompletion(true);
          else
            this.questionHistory = history;
        }else
          this.$snotify.error("Some error occurred, please try after some time.");
      } else
        this.$snotify.error("Some error occurred, please try after some time.");
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  /************************************************************************************************************************************************************
   *                          If we add coBorrower or remove coBorrower then we need to reload the pos Details                                                *
   ************************************************************************************************************************************************************/
  async reloadPos(){
    try{
      if (this.$userType != "Borrower") 
        await this.listAllBorrowers();

      await this.getLogInBorrowerDetails();
      await this.getQuestionHistory();
      if(this.questionHistory.currentSection == 'PI')
        this.$refs.personalInfo['updateQuestionHistory'](this.questionHistory);
    }catch(error){
      console.log(error);
    }
  }

 

  /********************************************************************************************************************************************************
   *                            ON CLICKING TAB OF PARTICULAR BORROWER AND COBORROWER NAME                                                                                 *
   ********************************************************************************************************************************************************/
  async onTabLinkChange(mappingIndex: number, userType: string) {
    if (this.questionHistory.index == mappingIndex) return;
    let currentSection = JSON.parse(JSON.stringify(this.questionHistory.currentSection));
    this.questionHistory.index = mappingIndex;
    this.questionHistory.userType = userType;
    this.questionHistory.currentQuestion = 1;
    this.questionHistory.previousQuestion = 1;
    this.questionHistory.currentSection = userType == "borrower" ? "LP" : "PI";
    if(currentSection == 'PI')
      this.$refs.personalInfo['updateQuestionHistory'](this.questionHistory);
  }

/**********************************************************************************************************************************
 *              Fetch All borrower's list on loanTxnId ( this function not used for borrower's login)
 **********************************************************************************************************************************/
  public async listAllBorrowers() {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "common/getBorrowerPersonalInfo",
        {
          loanTxnId: this.$route.query.loanTxnId
        });
      if (response.status == 200 && response.data) {
        this.borrowers = response.data;
      } else {
        this.$snotify.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.wemloLoader = true;
  }

  /********************************************************************************************************************************
   *                   Select Borrower's POS Flow on Borrower's userId
   ********************************************************************************************************************************/
  public async selectBorrower(scope,userId) {
    try {
      if(scope){
        let valid = await this.$validator.validateAll(scope);
        if(!valid)
          return
      }
      if(this.selectedBorrowerId || userId){
        this.$router.push({
          path: this.$getCurrentUserTypePath("pos-questionnaire"),
          query: {
            loanTxnId: this.$route.query.loanTxnId,
            borrowerId: userId ? userId : this.selectedBorrowerId
          }
        });
      }
      this.selectedBorrowerId = null;
      this.$modal.hide('continuePosForBorrowers');
      await this.getLogInBorrowerDetails();
      await this.getQuestionHistory();
    } catch (error) {
      console.log(error);
    }
  }
   public getBorrowerName(borrowerIndex) {
    let name = null;
    if(this.borrowers.borrowerInfo[borrowerIndex].personalInfo)
      name = this.borrowers.borrowerInfo[borrowerIndex].personalInfo.name;
    else
      name = this.borrowers.borrowerInfo[borrowerIndex].name ? this.borrowers.borrowerInfo[borrowerIndex].name : this.borrowers.borrowerInfo[borrowerIndex];
    return name.firstName + " " + name.lastName;
  }

  public goToDashboard(){
     this.$router.push({path: this.$getCurrentUserTypePath('dashboard')})
    this.$modal.hide('continuePosForBorrowers');
  }

   /********************************************************************************************************************************************************
   *                            GET QUESTONNAIRE HISTORY ON FIRST LOAD OF POS                                                                             *
   ********************************************************************************************************************************************************/
  async mounted() {
    if (this.$userType != "Borrower") await this.listAllBorrowers();
    if (
      this.$route.query.hasOwnProperty("loanTxnId") &&
      this.$route.query.hasOwnProperty("borrowerId")
    ) {
      await this.getLogInBorrowerDetails();
      await this.getQuestionHistory();
    } else {
      this.$snotify.clear();
      this.$snotify.error("Some error occurred, please try after some time.");
      this.$router.go(-1);
    }
  }
}
