import { Component, Vue } from "vue-property-decorator";
import stores from '@/store/store';
const store: any = stores;
@Component
export default class QuestionnaireMixin extends Vue {
    public questionHistory:any = null;
    public holdNext = false;
    public lastQuestionConfig = {
        scope: null,
        currentSection: null,
        currentQuestion: null,
        userType: null,
        index: null,
        indexingFlow: null
    }

    public get stateList() {
        return store.state.sessionObject.states;
    }

    public async onPrevious(previousQuestion?: number, currentQuestion?: number, currentSection?: string, backHandled?: boolean) {
        let updatedHistory = {
            currentQuestion: currentQuestion, 
            previousQuestion: previousQuestion, 
            currentSection: currentSection ? currentSection : this.questionHistory.currentSection
        }
        let data = {
            history: this.questionHistory,
            updatedHistory: updatedHistory,
            posCompleted:false
        }
        this.$emit("callMountFunction",data);
    }
    public async onSkip(nextQuestion?: number, currentQuestion?: number, nextSection?: string) {
        let updatedHistory = {
            currentQuestion: nextQuestion, 
            previousQuestion: currentQuestion, 
            currentSection: nextSection ? nextSection : this.questionHistory.currentSection,
        }
        let data = {
            history: this.questionHistory,
            updatedHistory: updatedHistory,
            posCompleted:false
        }
        this.$emit("callMountFunction",data);
    }

    public get userType() {
        return store.state.posSessionConfig.userType;
    }

    public validateChildrenComponentForms = async ($children?: any, scope?: string) => {
        /**
         * Map the validation promise to an array fro all
         * the child component forms
         */
        let childValidationPromises = $children.map(async ($child) => {
            return $child.$validator.validateAll(scope);
        });
        /**
         * Wait for the promises to complete for each
         */
        let validStatus = await Promise.all(childValidationPromises);
        return validStatus.every(v => v == true)
    }

    public async onNext(nextQuestion?: number, currentQuestion?: number, scope?: string, nextSection?: string, userType?:string, index?:number) {
        /**
        * This will execute the validateAll fuction in all the child component
        * returning the state of validation whether it is valid or invalid
        */
       
        let childrensValid = await this.validateChildrenComponentForms(this.$children, scope);
        let valid = await this.$validator.validateAll(this.lastQuestionConfig.scope ? this.lastQuestionConfig.scope : scope);
        if(!valid){
            return;
        }
        this.holdNext = true;
        await this.checkPendingQuestionList(currentQuestion, nextQuestion, valid, childrensValid);
        this.holdNext = false;
        let updatedHistory = {
            currentQuestion: nextQuestion, 
            previousQuestion: currentQuestion, 
            currentSection: nextSection ? nextSection : this.questionHistory.currentSection,
            userType: userType ? userType : this.questionHistory.userType,
            index: index ? index : this.questionHistory.index
        }
        let data = {
            history: this.questionHistory,
            updatedHistory: updatedHistory,
            posCompleted:false
        }
        this.$emit("callMountFunction",data);
    }

    async checkPendingQuestionList(receiveQuestion?: number, nextQuestion?: number, valid?: boolean, childrensValid?: boolean) {
        let currentQuestion = this.lastQuestionConfig.currentQuestion ? this.lastQuestionConfig.currentQuestion : receiveQuestion;
        let currentSection = this.lastQuestionConfig.currentSection ? this.lastQuestionConfig.currentSection : this.questionHistory.currentSection;
        // let currentIndex = this.lastQuestionConfig.index ? this.lastQuestionConfig.index : this.posConfig.index;
        let userType = this.lastQuestionConfig.userType ? this.lastQuestionConfig.userType : this.questionHistory.userType;
        let index = this.questionHistory.pendingQuestionList[userType][0][currentSection].indexOf(currentQuestion);
        if (index > -1) {
            if (valid && childrensValid)
                this.questionHistory.pendingQuestionList[userType][0][currentSection].splice(index, 1);
        } else {
            if (!valid || !childrensValid)
                this.questionHistory.pendingQuestionList[userType][0][currentSection].push(currentQuestion);
        }
        if (nextQuestion && (nextQuestion - currentQuestion) > 1) {
            for (var i = currentQuestion + 1; i < nextQuestion; i++) {
                let index = this.questionHistory.pendingQuestionList[userType][0][currentSection].indexOf(i);
                if (index > -1) {
                    this.questionHistory.pendingQuestionList[userType][0][currentSection].splice(index, 1);
                }
            }
        }
    }
    

    public isLessThanTwoYears(commingBorrower) {
        let obj = commingBorrower.currentEmployment[0].startDate;
        let totalMonth = 0;
        if (obj.year && obj.month) {
            let currentDate = new Date();
            let currYear:any = currentDate.getFullYear();
            let currMonth:any = currentDate.getMonth() + 1;
            if(currMonth)
                totalMonth = totalMonth + parseInt(currMonth);
            totalMonth = totalMonth + ((currYear - parseInt(obj.year)) * 12 - parseInt(obj.month));
            return totalMonth < 24 ? true : false;
        }else
            return false;
    }

    public resolveName(name) {
        let fName = name.firstName ? name.firstName : ''
        let lName = name.lastName ? name.lastName : ''
        return fName + ' ' + lName;
    }

    public userName(commingBorrower): string {
        return this.resolveName(commingBorrower.personalInfo.name);
    }

    public years(start: number, end: number) {
        let years = [];
        for (let i = end; i > start; i--) {
            years.push(i);
        }
        return years;
    }

    get days() {
        let days = [];
        for (let i: any = 1; i < 32; i++) {
            i = i < 10 ? "0" + i : i;

            days.push(i);
        }
        return days;
    }

    get months() {
        let months = [];
        for (let i: any = 1; i < 13; i++) {
            i = i < 10 ? "0" + i : i;
            months.push(i);
        }
        return months;
    }

    get yearsPeriod() {
        let period = [];
        for (let i: any = 1; i < 101; i++) {
            i = i < 10 ? "0" + i : i;
            period.push(i);
        }
        return period;
    }

    public getRedirectRouteAfterCompletion(allAnswered: boolean) {
        if (this.$userType == "Borrower") {
          return allAnswered
            ? this.$router.replace("/borrower/dashboard")
            : this.$router.replace("/borrower/dashboard?complete=true");
        } else {
          this.$router.replace(this.$getCurrentUserTypePath("dashboard"));
        }
      }

}